body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f6f7;
}

input,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  /* --white: #ffffff;
  --card-background: #ffffff;
  --background: #f6f6f7;
  --input-background: #f6f6f7;
  --button-border: #3e3e3e;
  --divider: #e0e1e6;
  --text-color: #202223;
  --dark-grey: #6a6c6c;
  --light-grey: #a0a0a0;
  --primary: #0f48eb; */
  --white: #000000;
  --dark-background: #181818;
  --background: #1c1c1c;
  --card-background: #232323;
  --input-background: #282828;
  --divider: #2e2e2e;
  --button-border: #3e3e3e;
  --text-color: #ffffff;
  --dark-grey: #cbcbcb;
  --light-grey: #767676;
  --primary: #0f48eb;
}

.dark-mode {
  --white: #000000;
  --dark-background: #181818;
  --background: #1c1c1c;
  --card-background: #232323;
  --input-background: #282828;
  --divider: #2e2e2e;
  --button-border: #3e3e3e;
  --text-color: #ffffff;
  --dark-grey: #cbcbcb;
  --light-grey: #767676;
  --primary: #0f48eb;
}

._contentEditable_uazmk_379 {
  padding: 0 !important;
  margin: 0 !important;
}

.mdxeditor-popup-container {
  z-index: 9999 !important;
}

._toolbarRoot_uazmk_160 {
  background: var(--divider) !important;
}
